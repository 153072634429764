import logo from './logo.jpg'
import './App.css';

function App() {
  return (
    
            <div className="App">
          <header className="App-header">
              <p>법무법인 나란 채무조정<img className ="img" src={logo} alt="법무법인 로고" ></img></p>
                </header>
                <main className="main">
                    <section>
                        <table>
                      <caption className="line">
                          <p className="c">채무조정이란? </p>
                            </caption>
                            <tbody>
                                <tr>
                                    <td>
                                        <p className="c">
                                            나라에 있는 제도를 활용해 이자 100% 면제<br />
                                            모든 채무에서 최소 30%에서 최대 50%까지 원금 탕감이 가능합니다.<br />
                                            이런 제도를 잘 모르시다 보니 활용을 안 하시는 거고<br />
                                            법무법인 변호사분이 대면으로 상담을 진행해드립니다.
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <a
                            className="TEL"
                            href="tel:01079395983"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            클릭시 자동 전화 연결
                        </a>
                        <br />
                        <a
                            className="TEL"
                      href="http://qr.kakao.com/talk/b7czcIja8QL7qLo57H5LGMVurYU-" // 카카오톡 연결 링크로 교체
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            클릭시 자동 카카오톡 연결
                        </a>
                    </section>
                </main>
                <footer>
                    <p className="sc">회사 : SB 마케팅 담당자: 왕석균 전화번호:010-7939-5983</p>
                    <p className="sc">주소: 서울 도봉구 도봉로 164길 43 무궁화빌딩</p>
                </footer>
            </div>
        

  );
  
}

export default App;
